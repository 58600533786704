const state = {active: '1'}

const mutations = {
    SET_ACTIVE: (state, active) => {
        state.active = active ? active: '1'
    },
}

const actions = {
    active({ commit }, active) {
        commit('SET_ACTIVE', active)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
