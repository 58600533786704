import {Base64} from 'js-base64'
import { getToken} from '@/utils/auth'
import store from '@/store'
import { Notification } from 'element-ui';

let util = {

};

util.title = function(title) {
    title = title ? (title + ' - 大明豪') : '大明豪';
    window.document.title = title;
};

util.paginator = function(total) {
    return {total:total.total, size:total.per_page, current:total.current_page}
};

util.uploadHeaders = function() {
    return {
        Authorization : 'Bearer ' + Base64.decode(getToken().toString())
    }
};

// 弹框实例
let NotificationInstance = null

util.hasPermission = function(meta) {

    if (NotificationInstance) {
        NotificationInstance.close()
    }

    if(!meta || !meta.permission){
        return true
    }

    if(meta.permission === 'muyu'){
        NotificationInstance  = Notification.error({title: '提示',message: '您没有权限进行此操作'})
    }

    let permission = 'api.' + meta.permission

    // 开始鉴权
    return store.state.role['forbid'].indexOf(permission) < 0
};

export default util;
