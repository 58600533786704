import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './plugins/element.js'
import router from './router'
import http from '@/utils/http'
import request from '@/utils/request'
import cache from '@/utils/cache'
import util from './utils/util'
import global from './utils/global'
import { Loading } from 'element-ui'
import { checkRedirectToken } from './utils/auth'
import BaiduMap from 'vue-baidu-map'
import VueClipboard from 'vue-clipboard2'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import * as echarts from "echarts";
import { creates} from '@/api'

Vue.prototype.$echarts = echarts

Vue.use(BaiduMap,{ak:'IVIgB3ysvAoZd0QtiehGnN2k'})
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$request = request
Vue.prototype.$cache = cache

Vue.prototype.$util = util
Vue.prototype.$global = global
Vue.prototype.$loading = Loading

import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 全局分类组件
Vue.component('Paginator', resolve => require(['@/components/layout/Paginator'], resolve))

router.beforeEach((to, from, next)=>{
    NProgress.start();
    if(checkRedirectToken(to)){
        // 没登录直接重定向首页
        return next({ name:'basics.signin'})
    }
    var key = 'infocenter.user'

    var callback = (response) => {
        store.dispatch('auth/infocenter', response.user)
        store.dispatch('role/forbid', response.forbid)
    }
    
    // 从缓存里面找一下
    if(cache.session.has(key)){
        callback(cache.session.get(key))
    }

    // 设置选中
    store.dispatch('menu/active', to.meta.active)

    util.title(to.meta.title)
    next()
})
Vue.config.errorHandler = function (err) {
    creates({remark:err}).then(()=>{
      
            })
  },

router.afterEach((to) => {
    NProgress.done()

    // 验证权限
    store.dispatch('role/auth', util.hasPermission(to.meta))

    window.scrollTo(0, 0)
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
