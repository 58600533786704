/**
 * 50 => "¥50.00"
 * @param {float} price
 */
export function priceFormat(price) {
    price = parseFloat(price);
    return '¥' + (price !== price ? 0  : price.toFixed(2))
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
