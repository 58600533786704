const state = {auth: true, forbid:[]}

const mutations = {
    SET_AUTH: (state, auth) => {
        state.auth = auth
    },
    SET_FORBID: (state, forbid) => {
        state.forbid = forbid
    },
}

const actions = {
    auth({ commit }, auth) {
        commit('SET_AUTH', auth)
    },
    forbid({ commit }, forbid) {
        commit('SET_FORBID', forbid)
    }
}

const getters = {
    getForbid (state) {
        return state.forbid
      }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
