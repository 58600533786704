import http from '@/utils/http'
//import util from '@/utils/util'
//import store from '@/store'

let request = {

};

request.get = (url, params, permission) => {
    permission
    //store.dispatch('role/auth', util.hasPermission({permission}))
    return new Promise((resolve, reject) => {
        http.get(url, {
            params
        }).then(response => {
            resolve(response)
        }).catch(e => {
            reject(e.data)
        })
    })
};

request.post = (url, params, permission) => {
    permission
    //store.dispatch('role/auth', util.hasPermission({permission}))
    return new Promise((resolve, reject) => {
        http.post(url, params).then(response => {
            resolve(response)
        }).catch(e => {
            reject(e.data)
        })
    })
};

request.put = (url, params, permission) => {
    permission
    //store.dispatch('role/auth', util.hasPermission({permission}))
    return new Promise((resolve, reject) => {
        http.put(url, params).then(response => {
            resolve(response)
        }).catch(e => {
            reject(e.data)
        })
    })
};

request.delete = (url, permission) => {
    permission
    //store.dispatch('role/auth', util.hasPermission({permission}))
    return new Promise((resolve, reject) => {
        http.delete(url).then(response => {
            resolve(response)
        }).catch(e => {
            reject(e.data)
        })
    })
};


export default request;