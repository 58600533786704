import Cookies from "js-cookie";
import { get } from 'lodash'

export function getToken() {
    return Cookies.get("Token");
}

export function removeToken() {
    return Cookies.remove("Token");
}

export function setToken(token) {
    return Cookies.set("Token", token);
}

export function getUser() {
    return Cookies.get("User");
}

export function removeUser() {
    return Cookies.remove("User");
}

export function setUser(user) {
    return Cookies.set("User", user);
}

export function checkRedirectToken (to){
    let auth = get(to, 'meta.auth')

    if(!auth){
        return false;
    }

    return getToken() ? false : true;
}
