<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
  import { creates} from '@/api'
    export default {
        name: 'App',
        data() {
            return {
                mar:'',
                loading: {
                    close: () => {}
                },
            }
        },
        provide() {
            return {
                handelLoading: this.handelLoading,
            }
        },
        mounted() {
        window.addEventListener('error', event => {
            creates({remark:event.error}).then(()=>{
          
                })
        })
    },
     
        methods: {

            handelLoading(loading) {
                if (loading) {
                    this.loading = this.$loading.service({
                        //target: '#loadingInstance',
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.4)'
                    })
                } else {
                    this.loading.close()
                }
            },
        },
          
         
        
    }
</script>
<style lang="scss">
    @import '@/assets/scss/style.scss';
     @import '@/assets/scss/table.scss';
</style>

<style lang="scss">
    * {
        margin: 0;
        padding: 0;
    }

    html {
        font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        font-size: 16px;
        @font-face {
            /* 重命名字体名 */
            font-family: 'PingFang Bold';
            /* 引入字体 */
            src: url('./assets/font/PingFang Bold.ttf');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            /* 重命名字体名 */
            font-family: 'PingFang ExtraLight';
            /* 引入字体 */
            src: url('./assets/font/PingFang ExtraLight.ttf');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            /* 重命名字体名 */
            font-family: 'PingFang Heavy';
            /* 引入字体 */
            src: url('./assets/font/PingFang Heavy.ttf');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            /* 重命名字体名 */
            font-family: 'PingFang Light';
            /* 引入字体 */
            src: url('./assets/font/PingFang Light.ttf');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            /* 重命名字体名 */
            font-family: 'PingFang Medium';
            /* 引入字体 */
            src: url('./assets/font/PingFang Medium.ttf');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            /* 重命名字体名 */
            font-family: 'PingFang Regular';
            /* 引入字体 */
            src: url('./assets/font/PingFang Regular.ttf');
            font-weight: normal;
            font-style: normal;
        }
    }

    .pointer {
        cursor: pointer;
    }

    @media (max-width: 1600px) {
        html {
            font-size: 12px;
        }
    }

</style>
