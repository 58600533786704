const state = {orders: {states:[]},items: {channels:[],eyes:[]}, machining:{channels:[]}, customers:{channels:[]}, sales:{states:[]},items_attr:{sph:[]}}

const mutations = {
    SET_ORDERS: (state, orders) => {
        state.orders = orders
    },
    SET_ITEMS: (state, items) => {
        state.items = items
    },
    SET_MACHINING: (state, machining) => {
        state.machining = machining
    },
    SET_CUSTOMERS: (state, customers) => {
        state.customers = customers
    },
    SET_SALES: (state, sales) => {
        state.sales = sales
    },
    SET_ITEMS_ATTR: (state, items_attr) => {
        state.items_attr = items_attr
    }
}

const actions = {
    global({ commit }, storage) {
        commit('SET_ORDERS', storage.orders)
        commit('SET_ITEMS', storage.items)
        commit('SET_MACHINING', storage.machining)
        commit('SET_CUSTOMERS', storage.customers)
        commit('SET_SALES', storage.sales)
        commit('SET_ITEMS_ATTR', storage.items_attr)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
