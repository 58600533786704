import axios from 'axios'
import {Base64} from 'js-base64'
import { removeToken, getToken} from '@/utils/auth'
import { get } from 'lodash'
import {Notification} from 'element-ui';
//import store from '@/store'

const service = axios.create({
    baseURL:process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEV_BASE_API : process.env.VUE_APP_BASE_API,
    // baseURL:'http://test-api.dmhglasses.com',
    // baseURL:'https://dmh-api.dmhglasses.com',
    withCredentials: true,
    timeout: 300000,
    headers: {'Content-Type': 'application/json'},
})

service.interceptors.request.use((config) => {
            //单独控制 get_spike接口地址的请求时间
 if(config.url.indexOf("api/customers/import")>-1){ 
    config.timeout= 300000
 }
    try {
        config.headers.Authorization = 'Bearer ' + Base64.decode(getToken().toString())
    } catch (error) {
        config.headers.Authorization = ''
    }
    return config;
},(error) => {
    // 错误是根据状态码 做相应的操作
    return Promise.reject(error)
})

service.interceptors.response.use((response) => {
    return response.data
},(error) => {
    const status = get(error, 'response.status')
    var closeCallback =  () => {
        //window.location.reload()
    }
    var duration = 0
    switch (status) {
        case 400:
            error.message = '请求错误'
        break
        case 401:
            removeToken()
            error.message = '未授权，请登录'
            window.location.reload()
        break
        case 403:
            error.message = '您无权限进行此操作，如有需要联系超级管理员'
            closeCallback = ()=>{}
            duration = 2000
        break
        case 404:
            error.message = `请求地址出错: ${error.response.config.url}`
        break
        case 408:
            error.message = '请求超时'
        break
        case 500:
            error.message = '服务器内部错误，请联系技术人员或刷新页面'
        break
        case 501:
            error.message = '服务未实现'
        break
        case 502:
            error.message = '网关错误'
        break
        case 503:
            error.message = '服务不可用'
        break
        case 504:
            error.message = '网关超时'
        break
        case 505:
            error.message = 'HTTP版本不受支持'
        break
        default:
            error.message = '其他异常信息：' + status
        break
    }
    if(status != 401){
        Notification.error({title: '提示', message: error.message,duration:duration, onClose:closeCallback})
    }

    return Promise.reject(error)
})

export default service
