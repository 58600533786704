import { removeToken, setToken } from '@/utils/auth'

const state = {auth: false,user:{},token:null}

const mutations = {
    SET_USER: (state, user) => {
        state.user = user
    },
    SET_AUTH: (state, auth) => {
        state.auth = auth
    },
    SET_TOKEN: (state, token) => {
        state.token = token
        // cookie
        setToken(token)
    },
}

const actions = {
    signin({ commit }, user) {
        commit('SET_AUTH', true)
        commit('SET_USER', user.user)
        commit('SET_TOKEN', user.token)
    },
    signout({ commit },) {
        removeToken()
        commit('SET_AUTH', false)
        commit('SET_TOKEN', null)
        commit('SET_USER', {})
    },
    infocenter({ commit }, user) {
        commit('SET_USER', user)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
