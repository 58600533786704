import Vue from 'vue'
import {
    Button,
    Select,
    Option,
    Pagination,
    Input,
    Drawer,
    Form,
    FormItem,
    DatePicker,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Radio,
    RadioGroup,
    RadioButton,
    Switch,
    MessageBox,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Skeleton,
    SkeletonItem,
    Dialog,
    Notification,
    Upload,
    Avatar,
    Link,
    Badge,
    InputNumber,
    Empty,
    Image,
    Autocomplete,
    Descriptions,
    DescriptionsItem,
    PageHeader,
    Table,
    TableColumn,
    Icon,
    Tooltip,
    Message,
    Tag,
    Container,
    Aside,
    Main,
    Header,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Breadcrumb,
    BreadcrumbItem,
    Cascader,
    Tree,
    Popover
} from 'element-ui';

Vue.prototype.$ELEMENT = {size:'small'};
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$message = Message;

Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Pagination.name, Pagination);
Vue.component(Input.name, Input);
Vue.component(Drawer.name, Drawer);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxButton.name, CheckboxButton);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Radio.name, Radio);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(RadioButton.name, RadioButton);
Vue.component(Switch.name, Switch);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(Skeleton.name, Skeleton);
Vue.component(SkeletonItem.name, SkeletonItem);
Vue.component(Dialog.name, Dialog);
Vue.component(Upload.name, Upload);
Vue.component(Avatar.name, Avatar);
Vue.component(Link.name, Link);
Vue.component(Badge.name, Badge);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Empty.name, Empty);
Vue.component(Image.name, Image);
Vue.component(Autocomplete.name, Autocomplete);
Vue.component(Descriptions.name, Descriptions);
Vue.component(DescriptionsItem.name, DescriptionsItem);
Vue.component(PageHeader.name, PageHeader);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Icon.name, Icon);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Tag.name, Tag);
Vue.component(Container.name, Container);
Vue.component(Aside.name, Aside);
Vue.component(Main.name, Main);
Vue.component(Header.name, Header);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(Submenu.name, Submenu);
Vue.component(Breadcrumb.name, Breadcrumb);
Vue.component(BreadcrumbItem.name, BreadcrumbItem);
Vue.component(Cascader.name, Cascader);
Vue.component(Tree.name, Tree);
Vue.component(Popover.name, Popover);

